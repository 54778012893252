var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination"},[_c('div',{staticClass:"pagination__item"},[_c('button',{staticClass:"pagination__button pagination__button--prev",attrs:{"disabled":_vm.isDisablePrevButton},on:{"click":_vm.prevPage}},[_c('icon',{attrs:{"name":"triangle-left","inline":false}})],1)]),(!_vm.showStartSeparator)?[_c('div',{staticClass:"pagination__item"},[_c('button',{staticClass:"pagination__button pagination__button--page",on:{"click":_vm.setFirstPageActive}},[_vm._v(" "+_vm._s(_vm.pages[0])+" ")])]),_vm._m(0)]:_vm._e(),_vm._l((_vm.visiblePages),function(page){return _c('div',{key:page,staticClass:"pagination__item"},[_c('button',{staticClass:"pagination__button pagination__button--page",class:{
        'pagination__button--active': _vm.currentPage === page,
      },on:{"click":function($event){return _vm.setPage(page)}}},[_vm._v(" "+_vm._s(page)+" ")])])}),(!_vm.showEndSeparator)?[_vm._m(1),_c('div',{staticClass:"pagination__item"},[_c('button',{staticClass:"pagination__button pagination__button--page",on:{"click":_vm.setLastPageActive}},[_vm._v(" "+_vm._s(_vm.pages.at(-1))+" ")])])]:_vm._e(),_c('div',{staticClass:"pagination__item"},[_c('button',{staticClass:"pagination__button pagination__button--next",attrs:{"disabled":_vm.isDisableNextButton},on:{"click":_vm.nextPage}},[_c('icon',{attrs:{"name":"triangle-right","inline":false}})],1)]),(_vm.perPage)?_c('div',{staticClass:"pagination__per-page"},[_c('span',{staticClass:"pagination__title"},[_vm._v(_vm._s(_vm.perPageTitle))]),_vm._l((_vm.perPageOptions),function(option){return _c('button',{key:option,staticClass:"pagination__button pagination__button--page",class:{
        'pagination__button--active':
          option === _vm.currentPerPageOption,
      },on:{"click":function($event){return _vm.setPerPageOption(option)}}},[_vm._v(" "+_vm._s(option)+" ")])})],2):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination__separator"},[_c('span'),_c('span'),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination__separator"},[_c('span'),_c('span'),_c('span')])
}]

export { render, staticRenderFns }