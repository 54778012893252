<template>
  <button :class="buttonCssClass" @click="$emit('click')">
    <div v-if="isShowIcon" class="game-filter-button__icon">
      <Icon :name="icon" group="minigames" />
    </div>
    <span class="game-filter-button__title">
      {{ title }}
    </span>
  </button>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'GameFilterButton',
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      default: 'large' | 'small',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonCssClass() {
      return [
        'game-filter-button',
        `game-filter-button--${this.kind}`,
        this.active ? 'game-filter-button--active' : '',
      ];
    },
    isShowIcon() {
      return this.icon.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-filter-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  color: #979797;
  font-size: 14px;
  line-height: 18px;
  position: relative;

  &:hover {
    color: #f5f5f7;
    background-color: #595a61;
  }

  &--large {
    flex-direction: column;
    padding: 12px 8px;
    justify-content: center;
    background: #2d2d30;
    border: 1px solid rgba(245, 245, 247, 0.12);
    border-radius: 8px;

    &:before {
      border-radius: 10px;
      inset: -4px;
    }
  }

  &--small {
    min-height: 32px;
    color: #979797;
    background: #161516;
    padding: 4px 6px;
    font-size: 14px;
    line-height: 18px;
    border: none;
    border-radius: 6px;

    &:before {
      border-radius: 8px;
      inset: -3px;
    }
  }

  &--active {
    color: #f5f5f7;
    background-color: #595a61;

    &:before {
      content: '';
      position: absolute;
      background: linear-gradient(90deg, #f87d28 0%, #facc59 171.54%);
      z-index: -1;
    }
  }
}

.game-filter-button__icon {
  .game-filter-button--large & {
    color: #f5f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 4px;
    border-radius: 8px;
    box-shadow: inset 0px 0px 0px 1px rgba(245, 245, 247, 0.12);
    background: linear-gradient(180deg, #2c2d2e 0%, #0e0f0f 100%);
  }
}
</style>
