<template>
  <div class="minigames-dropdown-filter">
    <div class="minigames-dropdown-filter__button">
      <Button
        :text="$t('games.choose')"
        type="tertiary"
        size="regular"
        long
        @click="handleChoiseButtonClick"
      />
    </div>
    <ul
      v-if="isDropdownShow"
      v-click-outside="dropdownToggle"
      class="minigames-dropdown-filter__list"
    >
      <h2 class="minigames-dropdown-filter__list-title">
        {{ $t('games.choose') }}
      </h2>
      <li class="minigames-dropdown-filter__item">
        <GameFilterButton
          icon="all"
          :title="$t('games.all')"
          kind="small"
          :active="!value"
          @click="handleGameButtonClick(0)"
        />
      </li>
      <li
        v-for="game in games"
        :key="game[idKey]"
        class="minigames-dropdown-filter__item"
      >
        <GameFilterButton
          :icon="game.icon"
          :title="game.title"
          kind="small"
          :active="value === game[idKey]"
          @click="handleGameButtonClick(game[idKey])"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import GameFilterButton from '@components/v2/minigames/GameFilterButton.vue';

export default {
  name: 'MinigamesDropdownFilter',
  components: {
    Button,
    GameFilterButton,
  },
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    idKey: {
      type: String,
      required: true,
    },
    games: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDropdownShow: false,
    };
  },
  methods: {
    dropdownToggle() {
      this.isDropdownShow = !this.isDropdownShow;
    },
    handleChoiseButtonClick() {
      this.dropdownToggle();
    },
    handleGameButtonClick(gameId) {
      this.$emit('input', gameId);
      this.isDropdownShow = false;
    },
  },
};
</script>

<style scoped>
.minigames-dropdown-filter__list-title {
  text-align: center;
  margin-bottom: 16px;
}

.minigames-dropdown-filter__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 12px;
  background: #2d2d30;
  border-radius: 24px 24px 0 0;
  border: 1px solid rgba(245, 245, 247, 0.12);
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 95%;
  overflow: auto;
}

.minigames-dropdown-filter__item {
  list-style-type: none;
  isolation: isolate;
}
</style>
