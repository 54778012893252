<template>
  <div class="pagination">
    <div class="pagination__item">
      <button
        class="pagination__button pagination__button--prev"
        :disabled="isDisablePrevButton"
        @click="prevPage"
      >
        <icon name="triangle-left" :inline="false" />
      </button>
    </div>
    <template v-if="!showStartSeparator">
      <div class="pagination__item">
        <button
          class="pagination__button pagination__button--page"
          @click="setFirstPageActive"
        >
          {{ pages[0] }}
        </button>
      </div>
      <div class="pagination__separator">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </template>
    <div
      v-for="page in visiblePages"
      :key="page"
      class="pagination__item"
    >
      <button
        class="pagination__button pagination__button--page"
        :class="{
          'pagination__button--active': currentPage === page,
        }"
        @click="setPage(page)"
      >
        {{ page }}
      </button>
    </div>
    <template v-if="!showEndSeparator">
      <div class="pagination__separator">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="pagination__item">
        <button
          class="pagination__button pagination__button--page"
          @click="setLastPageActive"
        >
          {{ pages.at(-1) }}
        </button>
      </div>
    </template>
    <div class="pagination__item">
      <button
        class="pagination__button pagination__button--next"
        :disabled="isDisableNextButton"
        @click="nextPage"
      >
        <icon name="triangle-right" :inline="false" />
      </button>
    </div>
    <div v-if="perPage" class="pagination__per-page">
      <span class="pagination__title">{{ perPageTitle }}</span>
      <button
        v-for="option in perPageOptions"
        :key="option"
        class="pagination__button pagination__button--page"
        :class="{
          'pagination__button--active':
            option === currentPerPageOption,
        }"
        @click="setPerPageOption(option)"
      >
        {{ option }}
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'ListItemPagination',
  components: {
    Icon,
  },
  props: {
    itemsAmount: {
      type: Number,
      required: true,
    },
    itemsOnPageAmount: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Boolean,
      default: false,
    },
    perPageTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentPage: 1,
      currentPerPageOption: this.itemsOnPageAmount,
      start: 0,
      end: this.step,
    };
  },
  computed: {
    pagesAmountComputed() {
      return Math.ceil(this.itemsAmount / this.currentPerPageOption);
    },
    pages() {
      return Array(this.pagesAmountComputed)
        .fill(1)
        .map((el, idx) => idx + 1);
    },
    perPageOptions() {
      const optionOne = this.itemsOnPageAmount;
      const optionTwo = this.itemsOnPageAmount * 3;
      const optionThree = optionTwo * 2;
      return [optionOne, optionTwo, optionThree];
    },
    visiblePages() {
      return this.pages.slice(this.start, this.end);
    },
    showStartSeparator() {
      return this.visiblePages.some((el) => el === this.pages[0]);
    },
    showEndSeparator() {
      return this.visiblePages.some((el) => el === this.pages.at(-1));
    },
    isDisablePrevButton() {
      return this.currentPage < 2;
    },
    isDisableNextButton() {
      return this.currentPage === this.pages.length;
    },
  },
  watch: {
    currentPage(newValue) {
      this.$emit('change', {
        currentPage: newValue,
        perPage: this.currentPerPageOption,
      });
    },
  },
  methods: {
    setPerPageOption(option) {
      this.currentPage = 1;
      this.setFirstPageActive();
      this.currentPerPageOption = option;
    },
    setPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    setFirstPageActive() {
      this.currentPage = this.pages[0];
      this.start = 0;
      this.end = this.step;
    },
    setLastPageActive() {
      this.currentPage = this.pages.at(-1);
      this.start = this.currentPage - this.step;
      this.end = this.currentPage;
    },
    nextPage() {
      if (this.currentPage === this.visiblePages.at(-1)) {
        // TODO подумать можно ли не использовать вложенный if
        if (this.end + this.step > this.pages.length) {
          this.start = this.pages.length - this.step;
          this.end = this.pages.length;
        } else {
          this.start += this.step;
          this.end += this.step;
        }
      }
      this.currentPage += 1;
    },
    prevPage() {
      if (this.currentPage === this.visiblePages[0]) {
        // TODO подумать можно ли не использовать вложенный if
        if (this.start - this.step < 0) {
          this.start = 0;
          this.end = this.step;
        } else {
          this.start -= this.step;
          this.end -= this.step;
        }
      }
      this.currentPage -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 8px 16px;
  background: #2d2d30;
  border-radius: 6px;

  @include min-laptop() {
    justify-content: flex-start;
  }
}

.pagination__button {
  min-width: 32px;
  height: 30px;
  background: none;

  &:disabled {
    color: #979797;
  }

  &:not(:disabled) {
    color: #f5f5f7;
    border-radius: 4px;
    cursor: pointer;

    &:hover:not(.pagination__button--active) {
      border-color: #9a9a9b;
    }
  }
}

.pagination__button--page {
  background: none;
  border: 2px solid transparent;
}

.pagination__button--next,
.pagination__button--prev {
  display: block;
  align-items: center;
  border: none;
  padding: 0;
  width: 32px;
  height: 30px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.pagination__button--active {
  background: linear-gradient(#2d2d30, #2d2d30) padding-box,
    var(--highlights-yellow) border-box;
}

.pagination__separator {
  display: flex;
  align-items: center;
  column-gap: 3px;
  color: rgba(255, 255, 255, 0.5);

  span {
    width: 3px;
    height: 3px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
  }
}

.pagination__title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #838488;
  padding: 4px;
}

.pagination__per-page {
  display: none;

  @include min-laptop() {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-left: auto;
  }
}
</style>
