<template>
  <div class="minigames-filter">
    <ul class="minigames-filter__main-list">
      <li class="minigames-filter__item minigames-filter__item--long">
        <GameFilterButton
          :title="$t('games.all')"
          kind="large"
          :active="!value"
          @click="handlePopularGameButtonClick(0)"
        />
      </li>

      <li
        v-for="game in popular"
        :key="game[idKey]"
        class="minigames-filter__item minigames-filter__item--long"
      >
        <GameFilterButton
          :icon="game.vkPlayGameId.toLowerCase()"
          :title="game.title"
          kind="large"
          :active="value === game[idKey]"
          @click="handlePopularGameButtonClick(game[idKey])"
        />
      </li>

      <li
        v-if="more.length"
        class="minigames-filter__item minigames-filter__item--long"
      >
        <GameFilterButton
          :title="`Ещё ${moreGamesAmount}`"
          kind="large"
          :active="isMoreGamesBtnActive"
          @click="handleMoreButtonClick"
        />
      </li>
    </ul>
    <template v-if="more.length">
      <ul
        v-if="isDropdownShow"
        v-click-outside="dropdownToggle"
        class="minigames-filter__dropdown"
      >
        <li
          v-for="game in more"
          :key="game[idKey]"
          class="minigames-filter__item"
        >
          <GameFilterButton
            :icon="game.vkPlayGameId.toLowerCase()"
            :title="game.title"
            kind="small"
            :active="value === game[idKey]"
            @click="handleRestGameButtonClick(game[idKey])"
          />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import GameFilterButton from '@components/v2/minigames/GameFilterButton.vue';

export default {
  name: 'MinigamesTabsFilter',
  components: {
    GameFilterButton,
  },
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    idKey: {
      type: String,
      required: true,
    },
    popular: {
      type: Array,
      required: true,
    },
    more: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDropdownShow: false,
    };
  },
  computed: {
    moreGamesAmount() {
      return this.more.length;
    },
    isMoreGamesBtnActive() {
      return this.more.some(
        (game) => game[this.idKey] === this.value,
      );
    },
  },
  methods: {
    dropdownToggle() {
      this.isDropdownShow = !this.isDropdownShow;
    },
    handlePopularGameButtonClick(gameId) {
      this.$emit('input', gameId);
    },
    handleRestGameButtonClick(gameId) {
      this.$emit('input', gameId);
      this.isDropdownShow = false;
    },
    handleMoreButtonClick() {
      this.dropdownToggle();
    },
  },
};
</script>

<style lang="scss" scoped>
$highlightOffset: 3px;

.minigames-filter {
  width: 100%;
  position: relative;
}

.minigames-filter__main-list {
  width: 100%;
  display: flex;
  gap: 8px;
  padding: $highlightOffset 0;
}

.minigames-filter__item {
  list-style-type: none;
  position: relative;
  isolation: isolate;

  &--long {
    width: 100%;
  }
}

.minigames-filter__dropdown {
  max-width: 520px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  z-index: 100;
  top: calc(100% + 5px);
  right: 0;

  padding: 12px 8px;
  background: #2d2d30;
  border-radius: 8px;
  border: 1px solid rgba(245, 245, 247, 0.12);
}
</style>
